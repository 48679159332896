.the-sell {
  background-image: url('images/email-cap-2-opt.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  padding: 3rem;
  padding-bottom: 5rem;
  border-top: 6px solid $brand-primary;
  border-bottom: 6px solid $brand-primary;

  @media(max-width: 768px) {
      padding: 5rem 1.5rem !important;
  }

  .capture {
    background: rgba(0,0,0,0.68);
    color: white;
    .start-here h3 {
      color: white;
    }

    .capture-form {
      background-color: rgba(28,28,28,0.82);
    }
  }
}