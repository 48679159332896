.how-it-works {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fefefe;
  @media (max-width: 768px) {
    padding: 0 2rem;
  }

  .title {
    margin-top: 2rem;
    font-family: $font-heading;
    letter-spacing: -2px;
  }
  .subtitle {
    font-size: 1.5rem;
    font-weight: 600;
  }
  span {
    font-weight: 700;
  }
  .step {
    margin: 4rem 0;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      padding: 0 1.5rem !important;
    }

    .step-img {
      display: flex;
      justify-content: center;
      width: 130px;
      margin: 0 auto;

      img {
        width: 100%;
      }

      @media (max-width: 768px) {
        width: 40%;
      }
    }
  }
}