@charset "utf-8";

//=========================================
// Set your brand colors
// $purple: #8A4D76;

// Update Bulma's global variables
// $family-sans-serif: "Nunito", sans-serif;
// $grey-dark: $brown;

// Update some of Bulma's component variables
// $navbar-height: 4rem;
//=========================================


// Override Bulma initial variables
$darkgrey: #424242;
$seagreen: #569863;
// $color-primary: #27ae60;

/* Customize the primary color for each business */
$color-primary: #569763; // Lot Bank primary
// $color-primary: #26374D;

$color-green: #8d8741;
$color-blue: #659dbd;
$color-peach: #daad86;
$color-brown: #bc986a;
$color-yellow: #fbeec1;
$color-black: #232323;
$color-white: #effeff;

// Define derived variables
// $primary: $seagreen;
$primary: $color-primary;

// Import only what you need from Bulma
@import "../node_modules/bulma/bulma.sass";