@import url('https://fonts.googleapis.com/css?family=Be+Vietnam:300,400,500,600,700&display=swap');

// Define font family variables
$font-heading: 'Be Vietnam', sans-serif;

// Define local SCSS variables
$seagreen: #569863;
// $seagreen: #2ecc71;
/* Make sure to change this for each site */
$brand-primary: #569763;

$color-green: #8d8741;
$color-blue: #659dbd;
$color-peach: #daad86;
$color-brown: #bc986a;
$color-yellow: #fbeec1;
$color-black: #232323;
$color-white: #eee;

// Import component styles
@import './styles/AboutUs';
@import './styles/EmailCapture';
@import './styles/FAQ';
@import './styles/Feature';
@import './styles/Hero';
@import './styles/HowItWorks';
@import './styles/TheSell';
@import './styles/Footer';

* {
  box-sizing: border-box;
}

body {
  background-color: #1c1c1c;
  width: 100vw;
  overflow: hidden;

  .App {
    width: 100%;
    .notification {
      display: block;
      position: fixed;
      z-index: 100;
      right: 2%;
      bottom: 0;
      padding: 1rem 2rem;
      padding-right: 2.5rem;
      min-width: 400px;
      box-shadow: 3px 5px 6px rgba(0, 0, 0, .2);
      @media (max-width: 768px) {
        width: 90%;
        margin: auto;
        align-self: center;
        right: 5.5%;
      }
    }
  }

  section {
    padding: 100px 0 !important;
  }
}