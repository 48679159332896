.feature-right {
  background-image: url('https://landleads.reiconversion.com/wp-content/uploads/2019/05/sell-your-land-property-how-can-we-help-background.jpg');
  border-top: solid 5px #569863;
}

.feature-left {
  background-image: url('https://landleads.reiconversion.com/wp-content/uploads/2019/05/sell-your-land-property-forestry-property.jpeg');
  margin-bottom: 0;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.feature-section {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: solid 5px #569863;
  // adjust styles for empty columns
  .feature-empty {
    min-height: 250px;
  }

  // adjust styles for column containing all feature info
  .feature {
    background-color: rgba(255, 255, 255, 0.9);

    .container {
      padding:  3rem 4.25rem 3rem 4rem;
      @media (max-width: 768px) {
        padding: 2rem 2.5rem;
      }
      .title {
        font-size: 2rem;
      }
      .bullets {
        list-style: disc;
        li {
          margin-left: 2rem;
        }
      }

      .cash-offer {
        background-color: #ffffff;
        margin-top: 1.5rem;
        padding: 2rem;
        @media (max-width: 768px) {
          padding: 1rem;
        }
        p {
          font-weight: 500; // RITM0668124
        }
        .button {
          color: white;
          border: none;
          font-weight: 500;
          background-color: orange;
          margin-top: 1.5rem;
          transition: background-color 0.15s ease-in;
          &:hover {
            background-color: #02ad88;
          }
        }
      }
    }
  }
}