.Footer {
  background-color: #1c1c1c;
  padding: 3rem 5rem;

  @media(max-width: 768px) {
    padding: 3rem 2rem;
  }

  .footer-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media(max-width: 768px) {
      flex-direction: column;
      text-align: center;
    }
  }

  .footer-logo {
    height: 72px;
    margin-bottom: 8px;

    @media(max-width: 768px) {
      height: 60px;
    }
  }

  .footer-brand {
    font-size: 3.5rem;
    font-family: $font-heading;
    letter-spacing: -4px;

    @media(max-width: 768px) {
      font-size: 2.8rem;
      letter-spacing: -4px;
    }
  }
  .footer-contact {
    font-size: 1.6rem;

    @media(max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  h2 {
    color: #efefef;
  }

  p {
    font-size: .75rem;
    // color: #2c2c2c;
    color: rgba(darken($color-white, 40), .3);

    @media(max-width: 768px) {
      text-align: center;
      margin-top: 1rem;
    }
  }
}