.capture {
  background: rgba(255,255,255,0.83);
  margin-top: 1rem;
  max-width: 960px;
  width: 960px;
  box-shadow: 8px 10px 10px rgba($color-black, 0.3);
  border-radius: 3px;

  @media (max-width: 768px) {
    width: auto;
  }

  .capture-features {
    padding: 2rem;

    .media {
      display: flex;
      align-items: center;
    }

    @media (max-width: 768px) {
      padding: 1rem;
    }
    p {
      font-weight: 500;
    }
    .subtitle {
      margin-bottom: .4rem;
    }
    .start-here {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 4rem;

      h3 {
        color: #1c1c1c;
        font-size: 1.6rem;
        font-weight: 600;
        margin-left: 1.3rem;
      }
      img {
        width: 180px;
      }
      @media (max-width: 1024px) {
        display: none;
      }

      .email-capture-arrow {
        color: #ffffff;
        font-size: 8rem;
        margin-right: 2rem;
        text-shadow: 0px 2px 5px rgba(0,0,0,.2);
      }
    }
  }
  .capture-form {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 1.5rem;
    overflow: hidden;

    h2 {
      font-size: 2rem;
      font-weight: bold;
    }
    p {
      color: #a2a2a2;
      font-size: 1.2rem;
      font-weight: bold;
      text-decoration: underline;
    }
    .control {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .capture-button {
      // background-color: $seagreen;
      background-color: $brand-primary;
      border: none;
      color: white;
      font-weight: 500;
      transition: background-color 0.15s ease-in;

      &:hover {
        background-color: darken($brand-primary, 18);
      }
    }
  }
}

.is-hidden {
  display: none;
}