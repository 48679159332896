.about-us {
  background-image: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), url('images/about-bg.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 4rem;
  color: white;
  position: relative;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 5rem 0.75rem !important;
  }

  .about-us-content-block {
    background-color: rgba(0,0,0,0.75);
    padding: 3.5rem 6rem;
    border-radius: 3px;
    box-shadow: 3px 10px 8px rgba($color-black, .18);
    @media (max-width: 768px) {
      padding: 1.75rem 1.25rem;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 960px;

    .subtitle {
      margin-top: 1.5rem;
      font-weight: 700;
      font-size: 1.5rem;
    }

    .about-us-text-block {
      text-align: left;
      font-size: 1.2rem;
      .signature {
        display: block;
        font-weight: 700;
        margin-top: 2rem;
        font-size: 1.4rem;
      }
    }
  }
}