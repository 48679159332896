.faq {
  background-color: #1c1c1c;
  #faq-title {
    margin-top: 3rem;
    margin-bottom: 4rem;
  }

  .column {
    padding: 0 2rem;
    .title {
      font-weight: 700;
    }
    .subtitle {
      font-weight: 600;
    }
    p {
      font-weight: 500;
    }
    hr {
      height: 1px;
      margin-top: 3rem;
    }
    @media (max-width: 768px) {
      padding: 0 3.5rem;
    }
  }
}