.hero {
    background-image: linear-gradient(rgba($color-black, 0.70), rgba($color-black, 0.40) 25%, rgba($color-black, 0.1) 45%, rgba(lighten($color-brown, 2), 0.4)), url('images/hero-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom right;
    justify-content: flex-start;
    padding-bottom: 5rem;

    // @media(max-width: 1200px) {
    //     background-image: linear-gradient(rgba($color-black, 0.80), rgba($color-black, 0.50) 25%, rgba($color-black, 0.1) 45%, rgba(lighten($color-brown, 2), 0.4)), url('images/hero-bg-lg.jpg');
    // }
    // @media(max-width: 768px) {
    //     background-image: linear-gradient(rgba($color-black, 0.80), rgba($color-black, 0.50) 25%, rgba($color-black, 0.1) 45%, rgba(lighten($color-brown, 2), 0.4)), url('images/hero-bg-sm.jpg');
    // }

    .navbar-brand {
        width: 100%;
        margin: 1rem 0;

        @media(max-width: 768px) {
            margin: 1rem 0 0 0;
        }
        
        .navbar-brand-text {
          font-family: $font-heading;
          color: $color-blue;
          font-size: 2rem;
          font-weight: 600;
          text-shadow: 2px 3px 5px rgba($color-black, .3);
          letter-spacing: -1.5px;

          @media(max-width: 768px) {
            font-size: 2rem;
          }
        }

        .level {
            width: 100%;
            .level-left {
                margin: 0;
                padding: 0;
            }
            .level-right {
                margin: 0;
                padding: 0;
            }
        }
    }

    .brand-logo {
        min-height: 46px;

        @media(max-width: 768px) {
            min-height: 42px;
            margin: 12px 0;
        }
    }

    .navbar-contact {
      @media(max-width: 768px) {
          font-size: 1rem;
        }
    }

    #hero-body {
        padding-top: 1.5rem;
        padding-bottom: 2rem;
        max-width: 1066px;
        align-self: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            max-width: 800px;
            font-size: 56px !important;
            text-shadow: 0px 0px 30px rgba($color-black, .7);
            margin-bottom: 30px;

            @media(max-width: 768px) {
                font-size: 48px!important;
                margin-top: -10px;
                margin-bottom: 40px;
            }
        }

        p {
            @media(max-width: 768px) {
                font-size: 24px!important;
            }
        }

        .video-container {
            position: relative;
            padding: 56% 0 25px 0;
            height: 0;

            #video {
                margin-top: 2rem;
                margin-bottom: 3rem;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    .navbar-item {
        color: white;
    }
}